import { faUser } from '@fortawesome/pro-light-svg-icons';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { UnpackNestedValue, useForm } from 'react-hook-form';

import IconInput from '../../../components/icon-input';
import { useForgotPassword } from '../../../services/authentication';
import FormStatus from '../../../components/form-status';

interface ForgotPasswordFormData {
  username: string;
}

function ForgotPasswordForm() {
  const [errors, setErrors] = useState<Error[]>([]);
  const forgotPassword = useForgotPassword(setErrors);
  const { register, handleSubmit } = useForm<ForgotPasswordFormData>();
  return (
    <Box pt={2}>
      <FormStatus errors={errors} />
      <form onSubmit={handleSubmit((data: UnpackNestedValue<ForgotPasswordFormData>) => forgotPassword(data.username))}>
        <IconInput label="Username" icon={faUser} textFieldProps={{ ...register('username') }} />
        <Box pt={4} display="flex">
          <Box flex={1}>
            <Button type="submit" variant="outlined" size="large">
              Password Reset
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default ForgotPasswordForm;
