import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, SxProps, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useContentStateLoadable } from '../../services/content-service';

interface AugmentProps {
  action: string;
  passwordLogin: boolean;
  to: string;
  name: string;
  tagLine?: string;
  features?: string[];
  watermark?: SxProps;
}

export function watermarkFrom(base64?: string): SxProps {
  if(!base64) return {};
  return {
    '&::before': {
      content: '""',
      background: `url(data:image/svg+xml;base64,${base64}) no-repeat`,
      backgroundSize: '12em',
      backgroundPosition: 'bottom',
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0.2em',
      left: '0',
      opacity: '0.06',
      zIndex: '0',
    }
  }
}

function useWatermark() {
  const content = useContentStateLoadable();
  const [watermark, setWatermark] = useState<any>({});
  useEffect(() => {
    if (content.state === 'hasValue') {
      setWatermark(watermarkFrom(content.getValue().logo))
    }
  }, [content]);
  return watermark;
}
function useContentState(): Pick<AugmentProps, 'name' | 'watermark' | 'tagLine' | 'features' | 'passwordLogin'> {
  const content = useContentStateLoadable();
  const watermark = useWatermark();
  if(content.state === 'loading') return {
    name: 'Loading...',
    features: [],
    passwordLogin: false,
  }
  const value = content.getValue();
  return {
    name: value.name,
    watermark,
    passwordLogin: value.passwordLogin,
    tagLine: value.tagLine,
    features: value.features
  }
}

export default function CurrentUserFormBanner({ action, to }: Pick<AugmentProps, 'action' | 'to'>) {
  const content = useContentState();
  return <UserFormBanner action={action} to={to} {...content} />;
}

export function UserFormBanner({ action, to, watermark, name, tagLine, features, passwordLogin }: AugmentProps) {
  return (
    <Box
      sx={{
        display: {
          xs: 'none',
          md: 'flex',
        },
        minWidth: '230px',
        flexDirection: 'column',
        position: 'relative',
        ...watermark,
        transition: 'background-color 2s',
        backgroundColor: (t) => t.palette.primary.main,
        borderRadius: (t) => t.spacing(2, 0, 0, 2),
      }}
    >
      <Box
        sx={{
          background: `linear-gradient(45deg, #00000066, transparent)`,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: (t) => t.spacing(4),
          zIndex: '1',
          borderRadius: (t) => t.spacing(2, 0, 0, 2),
        }}
      >
        <Box pb={1} display="flex" alignItems="center" justifyContent="center">
          <Box className={clsx('icon', 'icon-klouds-logo')} sx={{ color: (t) => t.palette.background.paper, fontSize: (t) => t.spacing(4) }} />
          <Box pl={1}>
            <Typography variant="subtitle2" sx={{ color: (t) => t.palette.background.paper }}>
              <Box>{name}</Box>
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" sx={{ color: (t) => t.palette.background.paper, textAlign: 'center' }}>
          <Box>{tagLine}</Box>
        </Typography>
        <Box py={4} display="flex" justifyContent="center">
          {passwordLogin && <Button
              component={NavLink}
              to={to}
              variant="outlined"
              size="large"
              sx={{
                color: (t) => t.palette.background.paper,
                textTransform: 'none',
                fontWeight: 300,
                borderRadius: '50vh',
                borderColor: (t) => t.palette.background.paper,
                '&:hover': {
                  borderColor: (t) => t.palette.background.paper,
                },
              }}
          >
            {action}
          </Button>
          }
        </Box>
        <Box flex={1} />
        <Box>
          { features?.map(feature => (
            <Box key={`feature-${feature}`} pb={2} display="flex" alignItems="center" sx={{ color: (t) => t.palette.background.paper }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              <Typography variant="body2" sx={{ paddingLeft: (t) => t.spacing(1) }}>
                {feature}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
