import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useLogout } from '../../../services/authentication';

export default function Logout() {
  const logout = useLogout();
  useEffect(() => {
    logout();
  }, [])
return <Box />
}