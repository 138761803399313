import { Box, CircularProgress, Theme, SxProps } from '@mui/material';
import React from 'react';

interface LoadingProps {
  size?: number;
  alignItems?: string;
  justifyContent?: string;
  color?: string;
  sx?: SxProps<Theme>;
}

export default function Loading({
  size, alignItems, justifyContent, color, sx,
}: LoadingProps) {
  return (
    <Box
      sx={{
        alignItems: alignItems ?? 'center',
        justifyContent: justifyContent ?? 'center',
        flex: 1,
        height: '100%',
        display: 'flex',
        ...sx,
      }}
    >
      <CircularProgress size={size ?? '24px'} sx={{ color }} />
    </Box>
  );
}
