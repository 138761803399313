import { ThemeProvider } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RecoilRoot } from 'recoil';
import CssBaseline from '@mui/material/CssBaseline';
import Routes from './routes';
import { useDynamicTheme } from './theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function DynamicThemeProvider({children}: PropsWithChildren) {
  const theme = useDynamicTheme();
  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </RecoilRoot>
  )
}

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <DynamicThemeProvider>
      <CssBaseline />
        <Routes />
      </DynamicThemeProvider>
    </RecoilRoot>
  </React.StrictMode>
);
