import { AuthServiceApiSdk } from '@hexlabsio/klouds-auth-service-sdk';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import environment from '../environment';
import { authenticatedAxios, sdkSelector, unAuthedSdkSelector, unauthenticatedAxios, useCall } from './sdk';
import { useSearch } from './search';

const getUnAuthedAuthSdk = new AuthServiceApiSdk(unauthenticatedAxios(`${environment.REACT_APP_API_ENDPOINT}/authpublic`));
const getAuthSdk = (jwt: string) => new AuthServiceApiSdk(authenticatedAxios(jwt, `${environment.REACT_APP_API_ENDPOINT}/auth`));

const { selector: contentState } = unAuthedSdkSelector('contentState', getUnAuthedAuthSdk, 'getContent');
const { selector: domainsState } = sdkSelector('domainsState', getAuthSdk, 'getDomains');
const { selector: principalsState } = sdkSelector('principalsState', getAuthSdk, 'getPrincipals');
const { selector: thingsState } = sdkSelector('thingsState', getAuthSdk, 'getThings');

export function useContentState(){ return useRecoilValue(contentState([{}])) }
export function useContentStateLoadable(){ return useRecoilValueLoadable(contentState([{}])) }


export function useDomains(){ return useRecoilValue(domainsState([{}])) }

export function useCreateDomain() {
  return useCall(getAuthSdk, 'createDomain');
}

export function useCreateThing() {
  return useCall(getAuthSdk, 'createThing');
}

export function useExchangeToken() {
  return useCall(() => getUnAuthedAuthSdk, 'createCode');
}

export function usePrincipals() {
  const { params: { domain: [selectedDomain] }} = useSearch();
  return useRecoilValue(principalsState([{domainId: selectedDomain}]));
}

export function useThings() {
  const { params: { domain: [selectedDomain] }} = useSearch();
  return useRecoilValue(thingsState([{domainId: selectedDomain}]));
}