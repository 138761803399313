import {
  Box, IconButton, Menu, MenuItem, Theme, Tooltip, useMediaQuery, useTheme, SxProps
} from '@mui/material';
import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBuilding, faUser, faKey, faCloud, faBoxesStacked, faEnvelope, faUsers, faBars, faFileChartColumn,
} from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import Gravatar from 'react-gravatar';
import {
  NavLink, To, useLocation, useNavigate,
} from 'react-router-dom';
import { useUser } from '../services/authentication';

interface SideLinkProps {
  icon?: IconProp;
  element?: JSX.Element;
  to: To;
  hoverable: boolean;
  title: string;
}

function sideLinkStyle(dark: boolean, hoverable: boolean): SxProps<Theme> {
  return {
    display: 'flex',
    position: 'relative',
    flex: hoverable ? undefined : 1,
    color: (t) => (dark && !hoverable ? 'black' : t.palette.background.paper),
    backgroundColor: (t) => (dark && hoverable ? t.palette.primary.dark : t.palette.primary.main),
    '&:hover': hoverable ? { '.edge': { visibility: 'visible' }, backgroundColor: (t) => t.palette.primary.dark } : {},
    padding: (t) => t.spacing(1, 1),
    margin: (t) => t.spacing(0.5, 0),
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: (t) => t.spacing(3),
    justifyContent: 'center',
    width: (t) => t.spacing(7),
  };
}

function sideLinkEdgeStyle(shown: boolean): SxProps<Theme> {
  return {
    zIndex: 1,
    visibility: shown ? 'visible' : 'hidden',
    width: (t) => t.spacing(5),
    height: '100%',
    position: 'absolute',
    backgroundColor: (t) => t.palette.primary.dark,
    right: (t) => t.spacing(-2),
    top: 0,
    borderRadius: '0 50% 50% 0',
  };
}

function SideLink({
  icon, element, to, hoverable, title,
}: SideLinkProps) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const dark = location.pathname.startsWith(to.toString());
  return (
    <Tooltip title={title} placement="right" PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [0, 8] } }] }}>
      <Box component={NavLink} to={`${to}?${search.toString()}`} sx={sideLinkStyle(dark, hoverable)}>
        <Box display="flex" sx={{ zIndex: 2 }}>
          {icon && <FontAwesomeIcon icon={icon} />}
          {element}
        </Box>
        <Box className="edge" sx={sideLinkEdgeStyle(hoverable && dark)} />
      </Box>
    </Tooltip>
  );
}

function KloudsIconLink(): JSX.Element {
  return (
    <Box
      component={NavLink}
      to="/"
      className={clsx('icon', 'icon-klouds-logo')}
      sx={{
        color: (t) => t.palette.common.white,
        fontSize: (t) => t.spacing(4),
        textDecoration: 'none',
        padding: (t) => t.spacing(1.5, 1),
      }}
    />
  );
}

function sidebarStyle(dockTop: boolean): SxProps<Theme> {
  return {
    display: 'flex',
    flexDirection: dockTop ? 'row' : 'column',
    backgroundColor: (t) => t.palette.primary.main,
    overflow: { xs: 'scroll', sm: 'visible' },
    overflowX: dockTop ? 'auto' : { xs: 'hidden', sm: 'initial' },
    overflowY: dockTop ? 'hidden' : 'auto',
    minWidth: (t) => t.spacing(7),
    minHeight: (t) => t.spacing(7),
    alignItems: 'center',
  };
}

function CurrentUserGravatar() {
  const user = useUser();
  const theme = useTheme<Theme>();
  return (
    <Box>
      <Gravatar email={user?.email ?? ''} default="mm" width={theme.spacing(3)} height={theme.spacing(3)} style={{ borderRadius: theme.spacing(2.5) }} />
    </Box>
  );
}

function ExtrasHamburger({ vertical }: { vertical: boolean }) {
  const navigate = useNavigate();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={vertical ? {} : { margin: (t) => t.spacing(0.5, 3) }}>
      <IconButton id="nav-hamburger" aria-controls={open ? 'nav-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
        <FontAwesomeIcon icon={faBars} style={{ color: 'white' }} />
      </IconButton>
      <Menu
        id="nav-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'nav-hamburger',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(`/organisations?${search.toString()}`);
          }}
        >
          <FontAwesomeIcon width="24px" icon={faBuilding} />
          <Box ml={1}>Organisations</Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(`/reports?${search.toString()}`);
          }}
        >
          <FontAwesomeIcon width="24px" icon={faFileChartColumn} />
          <Box ml={1}>Reports</Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(`/notifications?${search.toString()}`);
          }}
        >
          <FontAwesomeIcon width="24px" icon={faEnvelope} />
          <Box ml={1}>Notifications</Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(`/settings?${search.toString()}`);
          }}
        >
          <CurrentUserGravatar />
          <Box ml={1}>User Profile</Box>
        </MenuItem>
      </Menu>
    </Box>
  );
}

function ExtraLinks({ dockTop }: { dockTop: boolean }): JSX.Element {
  const smallHeight = useMediaQuery('(max-height:450px)');
  if (dockTop || smallHeight) {
    return <ExtrasHamburger vertical={!dockTop && smallHeight} />;
  }
  return (
    <Box sx={{ paddingBottom: (t) => t.spacing(dockTop ? 0 : 1) }}>
      <SideLink icon={faBuilding} to="/organisations" hoverable title="Organisations" />
      <SideLink icon={faFileChartColumn} to="/reports" hoverable title="Reports" />
      <SideLink icon={faEnvelope} to="/notifications" hoverable title="Notifications" />
      <SideLink to="/settings" element={<CurrentUserGravatar />} hoverable title="Settings" />
    </Box>
  );
}

function Sidebar({ dockTop }: { dockTop: boolean }) {
  return (
    <Box sx={sidebarStyle(dockTop)}>
      {!dockTop && <KloudsIconLink />}
      {!dockTop && (
        <Box>
          <SideLink icon={faCloud} to="/domains" hoverable={!dockTop} title="Sub-Domains" />
        </Box>
      )}
      <Box flex={1} display="flex" sx={dockTop ? { flexDirection: 'row', alignContent: 'center' } : { flexDirection: 'column', justifyContent: 'center' }}>
        {dockTop && <SideLink icon={faCloud} to="/domains" hoverable={!dockTop} title="Sub-Domains" />}
        <SideLink icon={faUser} to="/principals" hoverable={!dockTop} title="Principals" />
        <SideLink icon={faUsers} to="/groups" hoverable={!dockTop} title="Groups" />
        <SideLink icon={faKey} to="/roles" hoverable={!dockTop} title="Roles" />
        <SideLink icon={faBoxesStacked} to="/things" hoverable={!dockTop} title="Things" />
      </Box>
      <ExtraLinks dockTop={dockTop} />
    </Box>
  );
}

export default memo(Sidebar, isEqual);
