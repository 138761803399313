import {
  AlertColor,
  Box, SxProps,
  Typography,
} from '@mui/material';
import React from 'react';
import InfoAlert from './info-alert';

export interface Message {
  key: string;
  text: string;
  severity?: AlertColor;
}

interface MessagesProps {
  messages?: Message[];
  errors?: Error[];
  sx?: SxProps;
}

export default function FormStatus({ messages, errors, sx }: MessagesProps) {
  return (
    <Box id="notifications" sx={{ ...sx }}>
      <Box>
        {errors && errors.length > 0 && (
          <Box sx={{
            paddingBottom: (t) => t.spacing(2),
            '&:last-child': { paddingBottom: 0 },
          }}
          >
            <InfoAlert severity="error">
              {errors.map((error: Error) => (
                <Typography key={error.name ? error.name : error.message}>{error.message}</Typography>
              ))}
            </InfoAlert>
          </Box>
        )}
      </Box>

      <Box>
        {messages && messages.length > 0 && (
          <Box sx={{ paddingBottom: (t) => t.spacing(2), '&:last-child': { paddingBottom: 0 } }}>
            <InfoAlert
              severity={messages.filter((x) => x.severity).find((x) => x)?.severity ?? 'info'}
            >
              {messages.map((message) => (
                <Typography key={message.key}>{message.text}</Typography>
              ))}
            </InfoAlert>
          </Box>
        )}
      </Box>
    </Box>
  );
}
