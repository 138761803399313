import { Alert, AlertProps, lighten } from '@mui/material';
import React from 'react';

export default function InfoAlert(props: AlertProps): JSX.Element {
  const { sx, children, severity } = props;
  return (
    <Alert
      severity="info"
      {...props}
      sx={{
        ...(
          (!severity || severity === 'info')
            ? {
              backgroundColor: (t) => lighten(t.palette.primary.main, 0.95),
              '.MuiAlert-icon': { color: (t) => t.palette.primary.main },
            }
            : {}
        ),
        borderRadius: '20px',
        ...sx,
      }}
    >
      {children}
    </Alert>
  );
}
