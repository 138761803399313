import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faGithub, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faLockAlt, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomContent } from '@hexlabsio/klouds-auth-service-sdk';
import { Box, Button, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UnpackNestedValue, useForm } from 'react-hook-form';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { NavLink, useLocation } from 'react-router-dom';
import IconInput from '../../../components/icon-input';
import environment from '../../../environment';
import { useConfirmSignup, useGetProviderSession, useLogin } from '../../../services/authentication';
import { useContentState } from '../../../services/content-service';

interface LoginFormData {
  username: string;
  password: string;
}

interface LoginFormProps {
  errors: Error[];
  setErrors: Function;
  loading?: boolean;
}

export function Breadcrumb({ passwordLogin }: { passwordLogin: boolean }) {
  return (
    <Box display="flex">
      <Typography variant="subtitle2" sx={{ color: (t) => t.palette.primary.main }}>
        Login
      </Typography>
      {passwordLogin && (
        <>
          <Typography variant="subtitle2"
                      sx={{color: (t) => t.palette.text.disabled, paddingLeft: (t) => t.spacing(1)}}>
            /
          </Typography>
          <Box component={NavLink} to="/create-account" sx={{display: 'flex', textDecoration: 'none'}}>
            <Typography variant="subtitle2"
                        sx={{color: (t) => t.palette.text.disabled, paddingLeft: (t) => t.spacing(1)}}>
              Create Account
            </Typography>
          </Box>
        </>
      )
      }
    </Box>
  )
}

function linkFor(content: CustomContent, reference: string) {
  return `${content.auth.authEndpoint}/oauth2/authorize?client_id=${content.auth.clientId}&redirect_uri=https://${content.subDomain}.${environment.REACT_APP_KLOUDS_AUTH_URL}/login&state=github&response_type=code&identity_provider=${reference}`;
}

function LoginButton({color, icon, name, large, content, disabled}: { content: CustomContent, disabled?: boolean, color: string, icon: IconProp, name: string, large?: boolean}) {
  return (
    <Link href={disabled ? '#' : linkFor(content, name)}>
      <Box color={color} sx={{ cursor: 'pointer', width: large ? '250px' : 'unset', borderRadius: '20px', display: 'flex', alignItems: 'center', mb: large ? 2 : 0, p: large ? (t => t.spacing(1,2,1,2)) : 1, border: t => (large ? `1px solid ${t.palette.divider}` : 0) }}>
        <Box mr={2}>
          <FontAwesomeIcon icon={icon} size={large ? '3x' : '2x'} />
        </Box>
        { large && <Typography variant="subtitle2" color={t => t.palette.text.primary}>Login with {name}</Typography>}
      </Box>
    </Link>
  )
}

export function LoginButtons({ content, disabled, register, onSubmit }: { onSubmit?: React.FormEventHandler<HTMLFormElement>, register?: UseFormRegister<any>; content: CustomContent; disabled?: boolean}) {
  return (
    <>
      <Breadcrumb passwordLogin={content.passwordLogin} />
      <Box pt={content.passwordLogin ? 2: 0}>
        <form onSubmit={onSubmit} noValidate>
          { content.passwordLogin && <IconInput label="Username or Email" icon={faUser} textFieldProps={{...(register?.('username') ?? {}) }} /> }
          { content.passwordLogin && <IconInput label="Password" icon={faLockAlt} containerProps={{ pt: 3 }} textFieldProps={{ type: 'password', ...(register?.('password') ?? {}) }} /> }
          <Box pt={4} display="flex" alignItems="center" justifyContent="center">
            { content.passwordLogin && (
              <Box flex={1}>
                <Button type="submit" variant="outlined" size="large">
                  Login
                </Button>
              </Box>
            )
            }
            { content.passwordLogin && <Box flex={1} /> }
            <Box display="flex" flexDirection={content.passwordLogin ? 'row' : 'column'}>
              { content.providers.find(it => it.type ==='GOOGLE') && <LoginButton disabled={disabled} content={content} color="#DB4437" icon={faGoogle} name="Google" large={!content.passwordLogin} /> }
              { content.providers.find(it => it.type ==='FACEBOOK') && <LoginButton disabled={disabled} content={content} color="#4267B2" icon={faFacebook} name="Facebook" large={!content.passwordLogin} /> }
              { content.providers.find(it => it.type ==='GITHUB') && <LoginButton disabled={disabled} content={content} color="#333" icon={faGithub} name="Github" large={!content.passwordLogin} /> }
            </Box>
          </Box>
        </form>
      </Box>
    </>
  )
}

function LoginForm({ errors, setErrors, loading }: LoginFormProps) {
  const { search } = useLocation();
  const content = useContentState();
  const { register, handleSubmit } = useForm<LoginFormData>();
  const [, setMessages] = useState<any[]>([]);
  const login = useLogin(setErrors);
  const confirmUser = useConfirmSignup();
  const confirmProviderCode = useGetProviderSession(setErrors);

  useEffect(() => {
    const confirm = new URLSearchParams(window.location.search).get('confirm');
    if (confirm) {
      setMessages([{ key: 'confirm-user', text: 'Please check your email to confirm' }]);
    }
  }, [search]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const username = urlSearchParams.get('username');
    const code = urlSearchParams.get('code');
    const verify = urlSearchParams.get('verify');
    const provider = urlSearchParams.get('state');
    if(provider && code) {
      confirmProviderCode(code)
        .then(() => {
          urlSearchParams.delete('code');
          window.location.search = urlSearchParams.toString();
          setMessages([{ key: 'user-confirmed', text: 'User confirmed' }])
        })
        .catch(error => {
          setMessages([]);
          setErrors([error])
        });
    } else if (username && verify) {
      confirmUser(`${username}`, `${verify}`)
        .then(() => setMessages([{ key: 'user-confirmed', text: 'User confirmed' }]))
        .catch(error => {
          setMessages([]);
          setErrors([error])
        });
    }
  }, [search]);

  return (
    <LoginButtons
      content={content}
      register={register}
      onSubmit={handleSubmit((data: UnpackNestedValue<LoginFormData>) => login(data.username, data.password))}
    />
  );
}

export default LoginForm;
