import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useSearch() {
  const location = useLocation();
  const navigate = useNavigate();
  const setSingle = useCallback((key: string, value: string) => {
    const params = new URLSearchParams(location.search);
    params.delete(key);
    params.append(key, value);
    navigate({ search: params.toString() });
  }, [location.search]);
  const params = new URLSearchParams(location.search);
  const keyValues = [...params.entries()];
  const keys = [...params.keys()];
  const searchObject = keys.reduce((prev, key) => ({...prev, [key]: keyValues.filter(it => it[0] === key).map(it => it[1])}), {} as Record<string, string[]>);
  return {
    params: searchObject,
    setSingle
  }
}

export function useNavigation() {
  const location = useLocation();
  const navigateRaw = useNavigate();
  const navigate = useCallback((newLocation: string) => {
    navigateRaw({ pathname: newLocation, search: location.search });
  }, [location.search]);
  return {
    navigate
  }
}