import React, { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../error-fallback';
import Loading from '../loading';

// eslint-disable-next-line react/prefer-stateless-function
export default class ContainerBody extends React.Component<PropsWithChildren<{}>> {
  render() {
    const { children } = this.props;
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
      </ErrorBoundary>
    );
  }
}
