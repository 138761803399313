import { Box, Theme,  SxProps } from '@mui/material';
import { BoxTypeMap } from '@mui/material/Box/Box';
import { isEqual } from 'lodash';
import React, { memo, ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../error-fallback';
import Loading from '../loading';
import Breadcrumbs from './breadcrumbs';
import ContainerBody from './container-body';
import ContainerTitle from './container-title';

type ContainerChildren =
  | ReactElement<ContainerBody>
  | [ReactElement<ContainerTitle>, ReactElement<ContainerBody>]
  | [ReactElement<ContainerTitle>, ReactElement<Breadcrumbs>, ReactElement<ContainerBody>];

type BodyContainerProps = BoxTypeMap['props'] & {
  sx?: SxProps<Theme>;
  children: ContainerChildren;
};

function BodyContainer({
  children,
  ...props
}: BodyContainerProps & {
  children: ContainerChildren;
}): JSX.Element {
  return (
    <Box {...props} sx={{ display: 'flex', flex: 1, flexDirection: 'column', ...props.sx }}>
      <ErrorBoundary fallbackRender={ErrorFallback}>
        <React.Suspense fallback={<Loading />}>
          {children}
        </React.Suspense>
      </ErrorBoundary>
    </Box>
  );
}

export default memo(BodyContainer, isEqual);
