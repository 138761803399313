import { Box, Button, createTheme, Switch, ThemeProvider, Typography } from '@mui/material';
import { merge } from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import BodyContainer from '../../components/body-container';
import Breadcrumbs from '../../components/body-container/breadcrumbs';
import ContainerBody from '../../components/body-container/container-body';
import ContainerTitle from '../../components/body-container/container-title';
import Crumb from '../../components/body-container/crumb';
import { useDomains } from '../../services/content-service';
import { useSearch } from '../../services/search';
import { defaultTheme } from '../../theme';
import { FakeLogin } from '../user-management/login';


export function Domains() {
  const { params: {domain}, setSingle } = useSearch();
  const domains = useDomains();
  return (
    <Box display="flex" flexDirection="column" width="100%">
      {domains?.domains.map(it => (
        <ThemeProvider theme={createTheme(merge({...defaultTheme, ...(it as any).theme }))}>
          <Box mb="2em" width="100%">
            <Box alignItems="center" display="flex" justifyContent="center" width="100%">
              <Switch checked={domain?.[0] === (it as any).identifier} onClick={() => setSingle('domain', (it as any).identifier)} />
              <Typography sx={{ cursor: 'pointer', title: 'select'}} borderBottom="1px solid #ddd" textAlign="center" variant="subtitle1">
                {(it as any).subDomain + '.auth.klouds.io'}
              </Typography>
            </Box>
            <FakeLogin domain={it as any}/>
          </Box>
        </ThemeProvider>
      ))}
    </Box>
  );
}

export default function DomainContainer() {
  const { params: {domain} } = useSearch();
  return (
    <BodyContainer>
      <ContainerTitle>
        <Typography variant="h1">Subdomains</Typography><Box ml={2}><Button component={NavLink} to="/domains/create" variant="outlined">Create</Button></Box>
      </ContainerTitle>
      <Breadcrumbs>
        <Crumb>Subdomains</Crumb>
      </Breadcrumbs>
      <ContainerBody>
        { domain && <Domains />}
      </ContainerBody>
    </BodyContainer>
  )
}