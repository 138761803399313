import axios from 'axios';
import { useCallback, useState } from 'react';
import {
  atomFamily,
  RecoilState,
  selectorFamily,
} from 'recoil';
import qs from 'qs';
// eslint-disable-next-line import/no-cycle
import { jwtTokenQuery, useJwtToken } from './authentication';

type SDKResult<T> = T extends ({ statusCode: 201, result: infer X }) ? X : T extends ({ statusCode: 200, result: infer X }) ? X : never;

export function sdkSelector<SDK, FN extends keyof SDK>(key: string, sdk: (jwt: string) => SDK, call: FN): SDK[FN] extends ((...args: any) => any) ? { selector: (param: Parameters<SDK[FN]>) => RecoilState<SDKResult<Awaited<ReturnType<SDK[FN]>>>>, atom: (param: Parameters<SDK[FN]>) => RecoilState<SDKResult<Awaited<ReturnType<SDK[FN]>>>> } : never {
  const selectorForSdk = selectorFamily<any, any>({
    key,
    get: (params) => async ({ get }: any) => {
      const jwtToken = get(jwtTokenQuery);
      if (jwtToken) {
        const response = await (sdk(jwtToken)[call] as any)(...params);
        return response.statusCode >= 200 && response.statusCode <= 201 ? response.result : undefined;
      }
      return undefined;
    },
  });
  return {
    selector: selectorForSdk,
    atom: atomFamily({ key: `${key}State`, default: selectorForSdk }),
  } as any;
}

export function unAuthedSdkSelector<SDK, FN extends keyof SDK>(key: string, sdk: SDK, call: FN): SDK[FN] extends ((...args: any) => any) ? { selector: (param: Parameters<SDK[FN]>) => RecoilState<SDKResult<Awaited<ReturnType<SDK[FN]>>>>, atom: (param: Parameters<SDK[FN]>) => RecoilState<SDKResult<Awaited<ReturnType<SDK[FN]>>>> } : never {
  const selectorForSdk = selectorFamily<any, any>({
    key,
    get: (params) => async () => {
      const response = await (sdk[call] as any)(...params);
      return response.statusCode >= 200 && response.statusCode <= 201 ? response.result : undefined;
    },
  });
  return {
    selector: selectorForSdk,
    atom: atomFamily({ key: `${key}State`, default: selectorForSdk }),
  } as any;
}

export function sdkMultiSelector<SDK, FN extends keyof SDK>(key: string, sdk: (jwt: string) => SDK, call: FN): SDK[FN] extends ((...args: any) => any) ? { selector: (param: Parameters<SDK[FN]>[]) => RecoilState<SDKResult<Awaited<ReturnType<SDK[FN]>>>[]>, atom: (param: Parameters<SDK[FN]>[]) => RecoilState<{ input: Parameters<SDK[FN]>, output: SDKResult<Awaited<ReturnType<SDK[FN]>>> }[]> } : never {
  const selectorForSdk = selectorFamily<any, any>({
    key,
    get: (params) => async ({ get }: any) => {
      const jwtToken = get(jwtTokenQuery);
      if (jwtToken && params.length) {
        const responses = await Promise.all(params.map((it: any[]) => (sdk(jwtToken)[call] as any)(...it)));
        if (responses.some((response) => !(response.statusCode >= 200 && response.statusCode <= 201))) {
          return undefined;
        }
        return params.map((input: any[], index: number) => ({ input, output: responses[index].result }));
      }
      return undefined;
    },
  });
  return {
    selector: selectorForSdk,
    atom: atomFamily({ key: `${key}State`, default: selectorForSdk }),
  } as any;
}

interface CallStatus {
  loading: boolean;
  failed: boolean;
  succeeded: boolean;
  failureMessage?: string;
}

export function useCall<SDK, FN extends keyof SDK>(sdk: (jwt: string) => SDK, call: FN, onComplete?: SDK[FN] extends ((...args: any) => any) ? (result: SDKResult<Awaited<ReturnType<SDK[FN]>>>, ...params: Parameters<SDK[FN]>) => void : () => void): SDK[FN] extends ((...args: any) => any) ? { status: CallStatus, reset: () => void, trigger: (...params: Parameters<SDK[FN]>) => Promise<SDKResult<Awaited<ReturnType<SDK[FN]>>>> }: never {
  const jwt = useJwtToken();
  const [status, setStatus] = useState<CallStatus>({ loading: false, failed: false, succeeded: false });
  const trigger = useCallback(async (...params: any) => {
    setStatus({ loading: true, succeeded: false, failed: false });
    try {
      const result = await ((sdk(jwt)[call] as any)(...params));
      if (result.statusCode >= 200 && result.statusCode <= 201) {
        setStatus({ loading: false, succeeded: true, failed: false });
        onComplete?.(result.result, ...params);
      } else {
        setStatus({ loading: false, succeeded: false, failed: true });
      }
      return result.result;
    } catch (e) {
      setStatus({ loading: false, succeeded: false, failed: true, failureMessage: (e as any).message });
    }
    return undefined;
  }, [jwt]);
  return {
    status,
    trigger,
    reset: () => setStatus({ loading: false, failed: false, succeeded: false }),
  } as any;
}

export const unauthenticatedAxios = (url: string) => ({
  call: async (method: any, resource: any, path: string, body: any, pathParameters: any, queryParameters: any, multiQueryParameters: any, headers: any) => {
    const result = await axios(url + path, {
      method: method as any,
      data: body,
      params: { ...queryParameters, ...multiQueryParameters },
      headers,
      transformResponse: [],
    });
    return {
      statusCode: result.status,
      body: result.data,
      headers: result.headers as any,
    };
  },
});

export const authenticatedAxios = (jwtToken: string, url?: string) => ({
  call: async (method: any, resource: any, path: string, body: any, pathParameters: any, queryParameters: any, multiQueryParameters: any, headers: any) => {
    const updatedHeaders = {
      ...headers,
      Authorization: `Bearer ${jwtToken}`,
    };
    const result = await axios(url + path, {
      method: method as any,
      data: body,
      paramsSerializer: { serialize: (params) => qs.stringify(params) },
      params: { ...queryParameters, ...multiQueryParameters },
      headers: updatedHeaders,
      transformResponse: [],
      validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
    });
    return {
      statusCode: result.status,
      body: result.data,
      headers: result.headers as any,
    };
  },
});