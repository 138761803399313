import { faEnvelope, faLockAlt, faUser } from '@fortawesome/pro-light-svg-icons';
import { Box, Button } from '@mui/material';
import React, { useRef, useState } from 'react';
import { UnpackNestedValue, useForm } from 'react-hook-form';

import IconInput from '../../../components/icon-input';
import FormStatus from '../../../components/form-status';
import { useSignUp } from '../../../services/authentication';

interface SignUpFormData {
  username: string;
  email: string;
  organisation: string;
  password: string;
  confirmPassword: string;
}

function CreateAccountForm() {
  const [errors, setErrors] = useState<Error[]>([]);
  const signUp = useSignUp(setErrors);
  const {
    register,
    handleSubmit,
    formState: { errors: fieldErrors },
    watch,
  } = useForm<SignUpFormData>();
  const password = useRef({});
  password.current = watch('password');
  return (
    <Box pt={2}>
      <FormStatus errors={errors} sx={{ paddingBottom: 2 }} />
      <form onSubmit={handleSubmit((data: UnpackNestedValue<SignUpFormData>) => {
        signUp(data.username, data.email, data.password);
      })}
      >
        <IconInput label="Username" icon={faUser} textFieldProps={{ ...register('username') }} />
        <IconInput label="Email" icon={faEnvelope} containerProps={{ pt: 3 }} textFieldProps={{ ...register('email'), type: 'email' }} />
        <IconInput
          label="Password"
          icon={faLockAlt}
          containerProps={{ pt: 3 }}
          textFieldProps={{
            ...register('password'),
            type: 'password',
            helperText: 'Your password must be at least 8 characters and contain at least one letter, one number and one special character',
            inputProps: {
              pattern: '^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$',
              title: 'Must be at least 8 characters and contain at least one letter, one number and one special character',
            },
          }}
        />
        <IconInput
          label="Confirm Password"
          icon={faLockAlt}
          containerProps={{ pt: 3 }}
          textFieldProps={{
            ...register('confirmPassword', {
              validate: (value) => value === password.current,
            }),
            type: 'password',
            error: !!fieldErrors.confirmPassword,
            helperText: fieldErrors.confirmPassword && 'Must match password',
          }}
        />
        <Box pt={4} display="flex">
          <Box flex={1}>
            <Button type="submit" variant="outlined" size="large">
              Create Account
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default CreateAccountForm;
