import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import Gravatar from 'react-gravatar';
import { NavLink } from 'react-router-dom';
import BodyContainer from '../../components/body-container';
import Breadcrumbs from '../../components/body-container/breadcrumbs';
import ContainerBody from '../../components/body-container/container-body';
import ContainerTitle from '../../components/body-container/container-title';
import Crumb from '../../components/body-container/crumb';
import { usePrincipals } from '../../services/content-service';
import { useSearch } from '../../services/search';

export function Principals() {
  const principals = usePrincipals();
  return (
    <Box display="flex" flexDirection="column" width="100%">
      {principals.map(principal => (
        <Box display="flex" alignItems="center">
          <Box mr={2} height="30px"><Gravatar size={30} style={{borderRadius: "50vh"}} email={principal.identifier} default="mm" /></Box>
          {principal.identifier}
        </Box>
      ))}
    </Box>
  )
}

export default function PrincipalContainer() {
  const { params: { domain } } = useSearch();
  return (
    <BodyContainer>
      <ContainerTitle>
        <Typography variant="h1">Principals</Typography><Box ml={2}><Button component={NavLink} to="/principals/add" variant="outlined">Add</Button></Box>
      </ContainerTitle>
      <Breadcrumbs>
        <Crumb>Principals</Crumb>
      </Breadcrumbs>
      <ContainerBody>
        {domain?.[0] && <Principals />}
      </ContainerBody>
    </BodyContainer>
  );
}