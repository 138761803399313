import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, InputBaseProps, SxProps, TextField, TextFieldProps, Theme, Typography } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';
import React from 'react';

interface LoginInputProps {
  label?: string;
  placeholder?: string;
  icon?: IconProp;
  required?: boolean;
  containerProps?: BoxProps & {
    sx?: SxProps<Theme>;
  };
  textFieldProps?: TextFieldProps;
  endAdornment?: JSX.Element;
  inputProps?: InputBaseProps['inputProps'];
}

function IconInput({
  label, placeholder, icon, containerProps, textFieldProps, inputProps, required, endAdornment
}: LoginInputProps) {
  return (
    <Box {...containerProps}>
      {label && (
        <Typography variant="body2" pl={2} sx={{ color: (t) => t.palette.primary.main, paddingBottom: (t) => t.spacing(1) }}>
          {label}
        </Typography>
      )}
      <TextField
        {...textFieldProps}
        required={required !== false}
        fullWidth
        placeholder={label || (placeholder ?? '')}
        variant="outlined"
        inputProps={{
          ...inputProps,
          ...textFieldProps?.inputProps,
        }}
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        InputProps={{
          sx: { color: (t: any) => t.palette.text.primary },
          startAdornment: icon ? (
            <Box pl={1} sx={{ fontSize: (t) => t.spacing(3), color: (t) => t.palette.text.disabled }}>
              <FontAwesomeIcon icon={icon} />
            </Box>
          ) : undefined,
          endAdornment,
        }}
      />
    </Box>
  );
}

export default IconInput;
