import { Box, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSession, useUser } from '../services/authentication';
import Sidebar from './sidebar';

export function Navigation() {
  const user = useUser();
  const navigate = useNavigate();
  const dockTop = useMediaQuery('(max-width:600px)');
  useSession();
  useEffect(() => {
    if (!user) {
      navigate(`/login?redirect=${encodeURIComponent(window.location.pathname + window.location.search + window.location.hash)}`, { replace: true });
    }
  }, [user]);
  return (
    <Box display="flex" flex={1} flexDirection={dockTop ? 'column' : 'row'} minHeight={0} sx={{ backgroundColor: (t) => t.palette.background.default, overflow: 'hidden' }}>
      <Sidebar dockTop={dockTop} />
      <Box display="flex" overflow="auto" flex={1} sx={{ padding: (t) => t.spacing(dockTop ? 2 : 4, dockTop ? 0 : 4) }}>
        <Outlet />
      </Box>
    </Box>
  );
}
