import { Box } from '@mui/material';
import * as React from "react";
import { BrowserRouter, Route, Routes as Root, Navigate } from "react-router-dom";
import { Navigation } from './components/navigation';
import DomainContainer from './features/domains';
import CreateDomain from './features/domains/create-domain';
import PrincipalContainer from './features/principals';
import ThingsContainer from './features/things';
import CreateThingContainer from './features/things/create-thing';
import CreateAccount from './features/user-management/create-account';
import ForgotPassword from './features/user-management/forgot-password';
import Login from './features/user-management/login';
import Logout from './features/user-management/logout';

export default function Routes() {
  return (
    <BrowserRouter>
      <Root>
        <Route path="/login" element={<Login />}/>
        <Route path="/logout" element={<Logout />}/>
        <Route path="/create-account" element={<CreateAccount />}/>
        <Route path="/forgot-password" element={<ForgotPassword />}/>
        <Route element={<Navigation />}>
          <Route path="/domains" element={<DomainContainer />}/>
          <Route path="/domains/create" element={<CreateDomain />}/>
          <Route path="/principals" element={<PrincipalContainer />}/>
          <Route path="/things" element={<ThingsContainer />}/>
          <Route path="/things/add" element={<CreateThingContainer />}/>
          <Route path="/groups" element={<Box>Groups</Box>}/>
          <Route path="/roles" element={<Box>Roles</Box>}/>
          <Route path="/things" element={<Box>Things</Box>}/>
        </Route>
        <Route path="*" element={<Navigate to="/domains" replace />} />
      </Root>
    </BrowserRouter>
  )
}