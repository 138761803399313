import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import UserFormBanner from '../user-form-banner';
import CreateAccountForm from './create-account-form';

function Breadcrumb() {
  return (
    <Box display="flex">
      <Typography variant="subtitle2" sx={{ color: (t) => t.palette.primary.main }}>
        Create Account
      </Typography>
      <Typography variant="subtitle2" sx={{ color: (t) => t.palette.text.disabled, paddingLeft: (t) => t.spacing(1) }}>
        /
      </Typography>
      <Box component={NavLink} to="/login" sx={{ display: 'flex', textDecoration: 'none' }}>
        <Typography variant="subtitle2" sx={{ color: (t) => t.palette.text.disabled, paddingLeft: (t) => t.spacing(1) }}>
          Login
        </Typography>
      </Box>
    </Box>
  )
}

function Footer() {
  return (
    <Box pt={2} display="flex" justifyContent="center"  flexWrap="wrap">
      <Box component={NavLink} to="/login" sx={{ display: 'flex', textDecoration: 'none' }}>
        <Typography variant="body1" sx={{ color: (t) => t.palette.text.disabled }}>
          Already have an
        </Typography>
        <Typography variant="body1" sx={{ color: (t) => t.palette.text.primary, paddingLeft: (t) => t.spacing(0.5) }}>
          account?
        </Typography>
      </Box>
      <Typography variant="body1" sx={{ color: (t) => t.palette.text.disabled, paddingLeft: (t) => t.spacing(2) }}>
        /
      </Typography>
      <Box component={NavLink} to="/forgot-password" sx={{ display: 'flex', textDecoration: 'none' }}>
        <Typography variant="body1" sx={{ color: (t) => t.palette.text.disabled, paddingLeft: (t) => t.spacing(2) }}>
          Forgot
        </Typography>
        <Typography variant="body1" sx={{ color: (t) => t.palette.text.primary, paddingLeft: (t) => t.spacing(0.5) }}>
          password?
        </Typography>
      </Box>
    </Box>
  )
}

function CreateAccount() {
  return (
    <Box>
      <Helmet>
        <title>Create Account</title>
      </Helmet>
      <Container maxWidth="md" sx={{ paddingTop: (t) => t.spacing(4) }}>
        <Box display="flex" sx={{ backgroundColor: (t) => t.palette.background.paper, borderRadius: (t) => t.spacing(2) }}>
          <UserFormBanner action="Login" to="/login" />
          <Box p={4} flex={1}>
            <Breadcrumb />
            <CreateAccountForm />
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
}

export default CreateAccount;
