
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Provider } from '@hexlabsio/klouds-auth-service-sdk';
import {
  Box,
  Button, Checkbox,
  Container,
  createTheme,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useFilePicker } from "use-file-picker";
import { merge } from 'lodash';
import React, { useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { atom, useRecoilState } from 'recoil';
import BodyContainer from '../../../components/body-container';
import Breadcrumbs from '../../../components/body-container/breadcrumbs';
import ContainerBody from '../../../components/body-container/container-body';
import ContainerTitle from '../../../components/body-container/container-title';
import Crumb from '../../../components/body-container/crumb';
import { faGlobe, faTag, faBrowser, faUsers, faLink, faStar, faX } from '@fortawesome/pro-light-svg-icons';
import { faGoogle, faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons';
import { BlockPicker, HuePicker } from 'react-color';
import IconInput from '../../../components/icon-input';
import Loading from '../../../components/loading';
import { useCreateDomain } from '../../../services/content-service';
import { defaultTheme } from '../../../theme';
import { FakeLogin } from '../../user-management/login';


export function useColorPicker(defaultColor: string) {
  const [color, setColor] = useState(defaultColor);
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up('md'));
  const colorPicker = (
    <Box mb={2} display="flex" flexDirection="column" textAlign="center">
      <Box mb={2} mr={{md: 0, xs: 2}} sx={{ color: t => t.palette.primary.main }}>
        <Typography variant="body2">Primary Color</Typography>
      </Box>
      {
        large
          ? <BlockPicker colors={['#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e']} color={color} onChange={c => setColor(c.hex)} />
          : (
            <Box display="flex" mr={1.5}>
              <Box mr={2} width="4em" height="16px" sx={{ borderRadius: '5px', backgroundColor: color }}/>
              <HuePicker width={"100%"} color={color} onChange={c => setColor(c.hex)} />
            </Box>
          )
      }
    </Box>
  );
  return {
    color,
    colorPicker
  }
}

type Step1Type = {subDomain: string, redirectUrl: string, userPoolId: string, clientId: string, authEndpoint: string};
type Step2Type = {name: string; tagLine: string; color: string;  features: string[]; googleName?: string; facebookName?: string; githubName?: string; passwordLogin: boolean};
const stepData = atom<{ current: number, steps: [Step1Type | undefined] }>({ key: 'stepsState', default: { current: 0, steps: [undefined] }});

export function Step1() {
  const [data, setData] = useRecoilState(stepData);
  const { control, handleSubmit } = useForm<Step1Type>({ defaultValues: data.steps[0]});
  return (
    <Box display="flex" flexDirection="column">
      <Typography mb={2} textAlign="center" variant="subtitle1">Create your Subdomain</Typography>
      <Container maxWidth="md" sx={{ p: 3, backgroundColor: 'white', borderRadius: '20px', maxWidth: '825px' }}>
        <Box display="flex" flexDirection="column">
          <IconInput endAdornment={<Typography>.auth.klouds.io</Typography>} icon={faGlobe} label={"Subdomain"} textFieldProps={ { ...control.register("subDomain"), sx: {mb: 2} } } />
          <IconInput icon={faLink} label={"Redirect URL"} textFieldProps={ { ...control.register("redirectUrl"), sx: {mb: 2} } } />
          <IconInput icon={faUsers} label={"Cognito User Pool ID"} textFieldProps={ { ...control.register("userPoolId"), sx: {mb: 2} } } />
          <IconInput icon={faBrowser} label={"Cognito App Client ID"} textFieldProps={ { ...control.register("clientId"), sx: {mb: 2} } } />
          <IconInput icon={faGlobe} label={"Cognito Auth Endpoint"} textFieldProps={ { ...control.register("authEndpoint"), sx: {mb: 2} } } />
          <Button variant="outlined" onClick={handleSubmit(valid => {
            setData({ current: 1, steps: [valid, ...data.steps.slice(1)] as any })
          }, () => {})}>
            Next
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

export function useLogo() {
  const [openFileSelector, { filesContent, loading, clear, errors }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/svg+xml",
    multiple: false,
    limitFilesConfig: { max: 1 },
    maxFileSize: 1
  });
  const content = (
    <Box display="flex" flexDirection="column">
      { loading ? <Loading /> : (
        <>
          <Typography textAlign="center" variant="body2" sx={{ mb:2, color: (t) => t.palette.primary.main }}>
            App Logo
          </Typography>
          { filesContent[0] && <img alt="app-logo" src={filesContent[0].content}/> }
          { filesContent[0] ? <Button variant="outlined" onClick={() => clear()}>Clear Logo</Button> : <Button variant="outlined" onClick={() => openFileSelector()}>Add Logo...</Button> }
          <Typography>{errors?.[0]?.name ?? ''}</Typography>
        </>
      )}
    </Box>
  );
  return {
    image: filesContent[0]?.content.substring(26),
    content,
  }
}

export function Step2() {
  const [data, setData] = useRecoilState(stepData);
  const {trigger, status} = useCreateDomain();
  const { image, content } = useLogo();
  const { colorPicker, color } = useColorPicker('#34495e');
  const { control, handleSubmit, watch } = useForm<Step2Type>({ defaultValues: { features: [], passwordLogin: true}});
  const {fields, append, remove} = useFieldArray<any>({control, name: 'features'});

  const values = watch();
  const providers: Provider[] = [
    ...(values.googleName ? [{type: 'GOOGLE' as const, reference: values.googleName}] : []),
    ...(values.facebookName ? [{type: 'FACEBOOK' as const, reference: values.facebookName}] : []),
    ...(values.githubName ? [{type: 'GITHUB' as const, reference: values.githubName}] : []),
  ]
  return (
    <Box display="flex" flexDirection="column">
      <Typography mb={2} textAlign="center" variant="subtitle1">Configure your Login Experience</Typography>
      <Container maxWidth="md" sx={{ p: 3, backgroundColor: 'white', borderRadius: '20px', maxWidth: '825px' }}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection={{md: 'row', xs: 'column'}}>
            <Box mb={2}>
              {colorPicker}
              {content}
            </Box>
            <Box flex={1} ml={2}>
               <IconInput icon={faBrowser} label={"App Name"} textFieldProps={ { ...control.register("name"), sx: {mb: 2} } } />
               <IconInput icon={faTag} label={"Tag Line"} textFieldProps={ { ...control.register("tagLine"), sx: {mb: 2} } } />
              <Controller
                name="passwordLogin"
                control={control}
                render={({ field }) => {
                  return (
                    <Box display="flex" alignItems="center" mb={2}>
                      <Checkbox {...field} checked={field.value}/>
                      <Typography variant="body2" sx={{ color: (t) => t.palette.primary.main }}>
                        Allow Username and Password
                      </Typography>
                    </Box>
                  )
                }}
              />
              <IconInput containerProps={{flex: 1, mr: 2}} icon={faGoogle} label={"Google Provider Name"} textFieldProps={ { ...control.register("googleName"), sx: {mb: 2} } } />
              <IconInput containerProps={{flex: 1, mr: 2}} icon={faFacebook} label={"Facebook Provider Name"} textFieldProps={ { ...control.register("facebookName"), sx: {mb: 2} } } />
              <IconInput containerProps={{flex: 1}} icon={faGithub} label={"Github Provider Name"} textFieldProps={ { ...control.register("githubName"), sx: {mb: 2} } } />
              <Typography variant="body2" sx={{ color: (t) => t.palette.primary.main, paddingBottom: (t) => t.spacing(1) }}>Features</Typography>
              {fields.map((field, index) => (
                <IconInput key={field.id} containerProps={{flex: 1, mr: 2}} icon={faStar} label={`Feature ${index + 1}`} textFieldProps={ { ...control.register(`features.${index}`), sx: {mb: 2} } } endAdornment={
                  <Box title="Remove Feature" sx={{cursor: "pointer"}} onClick={() => remove(index)}><FontAwesomeIcon icon={faX} /></Box>
                } />
              ))}
              <Box textAlign="center" mb={2}>
                { fields.length < 3 && <Button variant="outlined" onClick={() => append('')}> Add Feature</Button> }
              </Box>
              <Box display="flex">
                <Box mr={2} flex={1}>
                  <Button fullWidth variant="outlined" color="info" onClick={() => setData(old => ({...old, current: old.current - 1}))}>
                    Back
                  </Button>
                </Box>
                <Box flex={1}>
                  <Button fullWidth variant="outlined" disabled={status.loading} onClick={handleSubmit(async valid => {
                    const part1 = data.steps[0]!;
                    const identifier = await trigger({ content: {
                        name: valid.name,
                        tagLine: valid.tagLine,
                        features: valid.features,
                        logo: image,
                        passwordLogin: valid.passwordLogin,
                        redirectUrl: part1.redirectUrl,
                        subDomain: part1.subDomain,
                        theme: { palette: { primary: { main: color } }},
                        providers: providers,
                        auth: {
                          type: 'COGNITO',
                          userPoolId: part1.userPoolId,
                          authEndpoint: part1.authEndpoint,
                          clientId: part1.clientId,
                        }
                      }});
                    if(identifier) setData({current: 2, steps: [undefined]});
                  })}>
                    { status.loading ? <Box mr={2}><Loading /></Box> : <></> }Create
                  </Button>
                </Box>
              </Box>
             </Box>

          </Box>
        </Box>
      </Container>
      <Box mt={2} mb={4}>
        <Typography textAlign="center" variant="subtitle1">Preview</Typography>
        <ThemeProvider theme={createTheme(merge(defaultTheme, {palette: { primary: { main: color}}}))}>
           <FakeLogin domain={{...values, logo: image, providers}} />
        </ThemeProvider>
      </Box>
    </Box>
  )
}

export function Step3() {
  return (
    <Box>Complete</Box>
  )
}

export function CreateDomainForm() {
  const [steps] = useRecoilState(stepData);

  return (
    <Box display="flex" flexDirection="column">
      { steps.current === 0 && <Step1 /> }
      { steps.current === 1 && <Step2 /> }
      { steps.current === 2 && <Step3 /> }
    </Box>
  )
}

export default function CreateDomain() {
  return (
    <BodyContainer>
      <ContainerTitle>
        Create Subdomain
      </ContainerTitle>
      <Breadcrumbs>
        <Crumb to="/domains">Subdomains</Crumb>
        <Crumb>Create</Crumb>
      </Breadcrumbs>
      <ContainerBody>
        <CreateDomainForm />
      </ContainerBody>
    </BodyContainer>
  )
}