import {
  atom, selector, useRecoilValue, useSetRecoilState,
} from 'recoil';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import axios from 'axios';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react'
import { useContentState } from './content-service';
import environment from '../environment';

const redirectUrl = `${environment.REACT_APP_KLOUDS_AUTH_URL}/login`;

function useUserPoolInfo() {
  const content = useContentState()
  return {
    redirectUrl: `https://${content.subDomain}.${redirectUrl}`,
    userPool: new CognitoUserPool({ UserPoolId: content.auth.userPoolId, ClientId: content.auth.clientId }),
    authEndpoint: content.auth.authEndpoint
  };
}

interface User {
  username: string;
  email: string;
  idToken: string;
  refreshToken: string;
  accessToken: string;
}

const userState = atom<User | undefined>({
  key: 'userState',
  default: undefined,
});

export const jwtTokenQuery = selector<string>({
  key: 'jwtTokenQuery',
  get: ({ get }) => {
    const user = get(userState);
    return user ? user.idToken : '';
  },
});

export const useJwtToken = () => useRecoilValue(jwtTokenQuery);

const extractUserFrom = (session: CognitoUserSession) => {
  const { 'cognito:username': username, email } = session.getIdToken().payload;
  return {
    username,
    email,
    idToken: session.getIdToken().getJwtToken(),
    accessToken: session.getAccessToken().getJwtToken(),
    refreshToken: session.getRefreshToken().getToken(),
  };
};

export const useLogin = (setErrors: Function) => {
  const userInfo = useUserPoolInfo();
  const setUser = useSetRecoilState(userState);
  return async (cognitoUsername: string, password: string) => {
    try {
      const session = await new Promise<CognitoUserSession>((resolve, reject) => {
        new CognitoUser({
          Username: cognitoUsername,
          Pool: userInfo.userPool,
        }).authenticateUser(
          new AuthenticationDetails({
            Username: cognitoUsername,
            Password: password,
          }),
          {
            onSuccess: (userSession) => resolve(userSession),
            onFailure: (error) => reject(error),
          },
        );
      });
      setUser(extractUserFrom(session));
    } catch (error) {
      setErrors([error]);
    }
  };
};

export const useLogout = () => {
  const userInfo = useUserPoolInfo();
  const navigate = useNavigate();
  return () => {
    const currentUser = userInfo.userPool.getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
    }
    navigate('/login');
  };
};

const getSession = (user: CognitoUser) => new Promise<CognitoUserSession>((resolve, reject) => {
  user.getSession((error: Error | null, session: CognitoUserSession) => {
    if (error) {
      return reject(error);
    }
    return resolve(session);
  });
});

export const useApplySession = () => {
  const userInfo = useUserPoolInfo();
  const setUser = useSetRecoilState(userState);
  const logout = useLogout();
  return async () => {
    try {
      const cognitoUser = userInfo.userPool.getCurrentUser();
      if (cognitoUser) {
        const session = await getSession(cognitoUser);
        if (session && session.isValid()) {
          setUser(extractUserFrom(session));
          return;
        }
      }
      setUser(undefined);
      return;
    } catch (error) {
      logout();
    }
  };
};



export const useGetProviderSession = (setErrors: Function) => {
  const applySession = useApplySession();
  const userInfo = useUserPoolInfo();
  return async (code: string) => {
    try {
      const response = await axios.request({
        url: `${userInfo.authEndpoint}/oauth2/token`,
        method: 'post',
        data: qs.stringify({
          grant_type: 'authorization_code',
          client_id: userInfo.userPool.getClientId(),
          redirect_uri: userInfo.redirectUrl,
          code,
        }),
      });
      const idToken = new CognitoIdToken({ IdToken: response.data.id_token });
      new CognitoUser({
        Username: idToken.payload['cognito:username'],
        Pool: userInfo.userPool,
      }).setSignInUserSession(
        new CognitoUserSession({
          IdToken: idToken,
          AccessToken: new CognitoAccessToken({
            AccessToken: response.data.access_token,
          }),
          RefreshToken: new CognitoRefreshToken({
            RefreshToken: response.data.refresh_token,
          }),
        }),
      );
      await applySession();
    } catch (error) {
      setErrors([error]);
    }
  };
};

const signUpUser = (userPool: CognitoUserPool, username: string, email: string, password: string) => new Promise((resolve, reject) => {
  userPool.signUp(username, password, [new CognitoUserAttribute({ Name: 'email', Value: email })], [], (error, data) => {
    if (error) reject(error);
    resolve(data);
  });
});

export function useConfirmSignup() {
  const userInfo = useUserPoolInfo();
  return async (cognitoUsername: string, confirmationCode: string) => {
    return new Promise<void>((resolve, reject) => {
      new CognitoUser({
        Username: cognitoUsername,
        Pool: userInfo.userPool,
      }).confirmRegistration(confirmationCode, true, (error: Error) => {
        if (error) {
          reject(error);
        }
        resolve();
      });
    })

  };
}

export const useSignUp = (setErrors: Function) => {
  const navigate = useNavigate();
  const userInfo = useUserPoolInfo();

  return async (username: string, email: string, password: string) => {
    try {
      await signUpUser(userInfo.userPool, username, email, password);
      navigate('/login?confirm=true');
    } catch (error) {
      setErrors([error]);
    }
  };
};

const forgetPassword = (userPool: CognitoUserPool, username: string) => new Promise<void>((resolve, reject) => {
  new CognitoUser({ Username: username, Pool: userPool }).forgotPassword({
    onSuccess: () => {
      resolve();
    },
    onFailure: (error) => {
      reject(error);
    },
  });
});

export const useForgotPassword = (setErrors: Function) => {
  const navigate = useNavigate();
  const userInfo = useUserPoolInfo();

  return async (username: string) => {
    try {
      await forgetPassword(userInfo.userPool, username);
      navigate('/login');
    } catch (error) {
      setErrors([error]);
    }
  };
};

const confirmPassword = (userPool: CognitoUserPool, username: string, verificationCode: string, password: string) => new Promise<void>((resolve, reject) => {
  new CognitoUser({ Username: username, Pool: userPool }).confirmPassword(verificationCode, password, {
    onSuccess: () => {
      resolve();
    },
    onFailure: (error) => {
      reject(error);
    },
  });
});

export const useChangePassword = (setErrors: Function) => {
  const navigate = useNavigate();
  const userInfo = useUserPoolInfo();

  return async (verificationCode: string, username: string, password: string) => {
    try {
      await confirmPassword(userInfo.userPool, username, verificationCode, password);
      navigate('/login');
    } catch (error) {
      setErrors([error]);
    }
  };
};

export const useSession = () => {
  const applySession = useApplySession();

  useEffect(() => {
    const interval = setInterval(() => {
      applySession();
    }, 300000);

    return () => clearInterval(interval);
  }, []);
};

export const useUser = () => useRecoilValue(userState);

function roleOrder(role: string): number {
  return role === 'admin' ? 0 : role === 'write' ? 1 : 2;
}

export function sortRoles(a: string, b: string): number {
  return roleOrder(a) - roleOrder(b);
}

export function operationsFrom(item?: any) {
  const methods = (item?.['@operation'] ?? []).map((it: any) => it.method.toLowerCase());
  return {
    get: methods.includes('get'),
    post: methods.includes('post'),
    patch: methods.includes('patch'),
    put: methods.includes('put'),
    delete: methods.includes('delete'),
  };
}
