import { EnvironmentBuilder } from '@hexlabs/env-vars-ts';

const environment = EnvironmentBuilder.create(
    'REACT_APP_ENV',
    'REACT_APP_API_ENDPOINT',
    'REACT_APP_KLOUDS_AUTH_URL',
).defaults({
    REACT_APP_ENV: 'local',
    REACT_APP_KLOUDS_AUTH_URL: 'auth.dev.klouds.io',
    REACT_APP_API_ENDPOINT: 'https://apis.auth.dev.klouds.io'
  },
).environment();

export default environment;