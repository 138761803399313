import { Box, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

function Crumbf({ to, children }: PropsWithChildren<{ to?: string }>): JSX.Element {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const text = typeof children === 'string';
  const content = text ? <Typography variant="body1">{children}</Typography> : children;
  if (to) {
    return (
      <Box component={NavLink} to={`${to}?${search.toString()}`} style={{ color: 'inherit', textDecoration: 'none' }}>
        {content}
      </Box>
    );
  }
  return <Box>{content}</Box>;
}

// eslint-disable-next-line react/prefer-stateless-function
export default class Crumb extends React.Component<PropsWithChildren<{ to?: string }>, {}> {
  render() {
    const { to, children } = this.props;
    return <Crumbf to={to}>{children}</Crumbf>;
  }
}
