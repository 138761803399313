import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import BodyContainer from '../../components/body-container';
import Breadcrumbs from '../../components/body-container/breadcrumbs';
import ContainerBody from '../../components/body-container/container-body';
import ContainerTitle from '../../components/body-container/container-title';
import Crumb from '../../components/body-container/crumb';
import { useThings } from '../../services/content-service';
import { useNavigation, useSearch } from '../../services/search';

export function Things() {
  const things = useThings();
  return (
    <Box display="flex" flexDirection="column" width="100%">
      {things.map(thing => (
        <Box display="flex" alignItems="center">
          {thing.name} {thing.type} {thing.identifier}
        </Box>
      ))}
    </Box>
  )
}

export default function ThingsContainer() {
  const { params: { domain } } = useSearch();
  const {navigate} = useNavigation();
  return (
    <BodyContainer>
      <ContainerTitle>
        <Typography variant="h1">Things</Typography><Box ml={2}><Button onClick={() => navigate('/things/add')} variant="outlined">Add</Button></Box>
      </ContainerTitle>
      <Breadcrumbs>
        <Crumb>Things</Crumb>
      </Breadcrumbs>
      <ContainerBody>
        {domain?.[0] && <Things />}
      </ContainerBody>
    </BodyContainer>
  );
}