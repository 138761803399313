import React, { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../error-fallback';
import Loading from '../loading';

// eslint-disable-next-line react/prefer-stateless-function
export default class ContainerTitle extends React.Component<PropsWithChildren<{}>> {
  render() {
    const { children } = this.props;
    const content = typeof children === 'string' ? <Typography variant="h1">{children}</Typography> : children;
    return (
      <Box display="flex" alignItems="center" sx={{ flexDirection: { xs: 'column', sm: 'row' }, mb: 1 }}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <React.Suspense fallback={<Loading />}>{content}</React.Suspense>
        </ErrorBoundary>
      </Box>
    );
  }
}
