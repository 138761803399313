import { Domain, TokenCode } from '@hexlabsio/klouds-auth-service-sdk';
import { Box, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useApplySession, useUser } from '../../../services/authentication';
import { useContentState, useExchangeToken } from '../../../services/content-service';

import UserFormBanner, {UserFormBanner as FakeBanner, watermarkFrom } from '../user-form-banner';
import LoginForm, { LoginButtons } from './login-form';

function FooterLinks() {
  return (
    <Box pt={2} display="flex" justifyContent="center" flexWrap="wrap">
      <Box component={NavLink} to="/create-account" sx={{ display: 'flex', textDecoration: 'none' }}>
        <Typography variant="body1" sx={{ color: (t) => t.palette.text.disabled }}>
          Don&apos;t have an
        </Typography>
        <Typography variant="body1" sx={{ color: (t) => t.palette.text.primary, paddingLeft: (t) => t.spacing(0.5) }}>
          account?
        </Typography>
      </Box>
      <Typography variant="body1" sx={{ color: (t) => t.palette.text.disabled, paddingLeft: (t) => t.spacing(2) }}>
        /
      </Typography>
      <Box component={NavLink} to="/forgot-password" sx={{ display: 'flex', textDecoration: 'none' }}>
        <Typography variant="body1" sx={{ color: (t) => t.palette.text.disabled, paddingLeft: (t) => t.spacing(2) }}>
          Forgot
        </Typography>
        <Typography variant="body1" sx={{ color: (t) => t.palette.text.primary, paddingLeft: (t) => t.spacing(0.5) }}>
          password?
        </Typography>
      </Box>
    </Box>
  )
}

function useRedirectOnSuccess() {
  const { search } = useLocation();
  const user = useUser();
  const content = useContentState();
  const {status, trigger: getExchangeToken} = useExchangeToken();
  const navigate = useNavigate();
  const apply = useApplySession();
  useEffect(() => {
    if(user) {
      if (window.location.hostname.match(/^(local.)?auth\.([^.]+\.)?klouds.io$/)) {
        const query = new URLSearchParams(search);
        const redirect = query.get('redirect');
        navigate(
          redirect
            ? {
              pathname: redirect.split('?')[0],
              search: redirect.split('?')[1],
              hash: redirect.split('#')[1],
            }
            : {pathname: '/'},
        )
      } else {
        (getExchangeToken({idToken: user.idToken, accessToken: user.accessToken, refreshToken: user.refreshToken}) as unknown as Promise<TokenCode>).then((code) => {
          window.location.href = `${content.redirectUrl}?code=${code.identifier}`;
        });
      }
    } else {
      apply();
    }
  }, [user, search]);

  return status;
}


export function FakeLogin({domain}: {domain: Omit<Domain['content'], 'auth' | 'redirectUrl' | 'subDomain' | 'theme'>}) {
  return (
    <Box>
      <Container maxWidth="md" sx={{ paddingTop: (t) => t.spacing(4) }}>
        <Box display="flex" sx={{ backgroundColor: (t) => t.palette.background.paper, borderRadius: (t) => t.spacing(2) }}>
          <FakeBanner passwordLogin={domain?.passwordLogin ?? true} action="Create Account" to="#" name={domain?.name ?? ''} features={domain?.features ?? []} tagLine={domain?.tagLine ?? ''} watermark={watermarkFrom(domain?.logo)} />
          <Box p={4} flex={1}>
            <LoginButtons content={domain as any} disabled />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

function Login() {
  const [errors, setErrors] = useState<Error[]>([]);
  const status = useRedirectOnSuccess();

  return (
    <Box>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Container maxWidth="md" sx={{ paddingTop: (t) => t.spacing(4) }}>
        <Box display="flex" sx={{ backgroundColor: (t) => t.palette.background.paper, borderRadius: (t) => t.spacing(2) }}>
          <UserFormBanner action="Create Account" to="/create-account" />
          <Box p={4} flex={1}>
            <LoginForm errors={errors} setErrors={setErrors} loading={status.loading} />
          </Box>
        </Box>
      </Container>
      <FooterLinks />
    </Box>
  );
}

export default Login;
