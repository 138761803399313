import { faBox, faTag } from '@fortawesome/pro-light-svg-icons';
import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import BodyContainer from '../../../components/body-container';
import Breadcrumbs from '../../../components/body-container/breadcrumbs';
import ContainerBody from '../../../components/body-container/container-body';
import ContainerTitle from '../../../components/body-container/container-title';
import Crumb from '../../../components/body-container/crumb';
import IconInput from '../../../components/icon-input';
import Loading from '../../../components/loading';
import { useCreateThing } from '../../../services/content-service';
import { useNavigation, useSearch } from '../../../services/search';

export function CreateThing() {
  const { params: { domain } } = useSearch();
  const { control, handleSubmit } = useForm<{type: string, identifier?: string, name?: string}>();
  const { trigger, status } = useCreateThing();
  const {navigate} = useNavigation();
  return (
    <Box display="flex" flexDirection="column">
      <Typography mb={2} textAlign="center" variant="subtitle1">Create new Thing</Typography>
      <Container maxWidth="md" sx={{ p: 3, backgroundColor: 'white', borderRadius: '20px', maxWidth: '825px' }}>
        <form onSubmit={handleSubmit(async valid => trigger({domainId: domain[0]}, valid).then(() => navigate('/things')))}>
          <Box display="flex" flexDirection="column">
            <IconInput icon={faBox} label={"Type"} textFieldProps={ { ...control.register("type"), sx: {mb: 2} } } />
            <IconInput icon={faTag} label={"Identifier"} required={false} textFieldProps={ { ...control.register("identifier", { required: false }), sx: {mb: 2} } } />
            <IconInput icon={faTag} label={"Name"} required={false} textFieldProps={ { ...control.register("name", { required: false }), sx: {mb: 2} } } />
          </Box>
          <Box display="flex">
            <Box mr={2}><Button fullWidth variant="outlined" color="info">Cancel</Button></Box>
            <Button disabled={status.loading} fullWidth variant="outlined" type="submit">
              { status.loading && <Box mr={2}><Loading /></Box> }Create
            </Button>
          </Box>
        </form>
      </Container>
    </Box>
  );
}



export default function CreateThingContainer() {
  const { params: { domain } } = useSearch();
  return (
    <BodyContainer>
      <ContainerTitle>
        <Typography variant="h1">Create Thing</Typography>
      </ContainerTitle>
      <Breadcrumbs>
        <Crumb to="/things">Things</Crumb>
        <Crumb>Create</Crumb>
      </Breadcrumbs>
      <ContainerBody>
        {domain?.[0] && <CreateThing />}
      </ContainerBody>
    </BodyContainer>
  );
}